import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DecodedToken, IParentInterfaces } from '../../interfaces/interfaces';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from '../user/user.service';
import { CookiesService } from '../cookies/cookies.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  TOKEN = makeStateKey<string>('TOKEN');

  constructor(
    private http: HttpClient,
    private userservice: UserService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookies: CookiesService,
    private transferState: TransferState
  ) {}

  checkAuthentication(): Observable<boolean> {
    console.log('checking auth');
    // if (isPlatformBrowser(this.platformId)) {
    let token = this.getToken();
    console.log(this.transferState.isEmpty);
    console.log(token);
    if (!token) {
      console.log('token not found');
      this.logout();
      return of(false);
    }
    return this.decodeToken(token).pipe(
      map((decoded) => {
        if (!this.isTokenExpired(decoded.exp)) {
          if (!this.getToken()) {
            this.transferState.set(this.TOKEN, token);
            this.cookies.set('token', token);
          }
          this.userservice.isLogged.next(true);
          this.userservice.user_info.next(decoded);
          console.log('found token', decoded);
          return true;
        } else {
          this.logout();
          return false;
        }
      }),
      catchError((error) => {
        console.error(error);
        this.logout();
        return of(false);
      })
    );
  }

  isTokenExpired(tokenExp: number): boolean {
    // Get the current UNIX timestamp in seconds
    const now = Math.floor(Date.now() / 1000);
    // Check if the current time is greater than the token's expiration time
    return now >= tokenExp;
  }

  getToken() {
    return isPlatformBrowser(this.platformId)
      ? this.cookies.get('token')
      : this.transferState.get(this.TOKEN, null);
  }
  setToken(token: string) {
    if (!this.getToken()) {
      this.transferState.set(this.TOKEN, token);
      this.cookies.set('token', token);
    }
    return this.decodeToken(token).pipe(
      tap((decoded) => {
        this.userservice.isLogged.next(true);
        this.userservice.user_info.next(decoded);
      })
    );
  }

  decodeToken(token?: string): Observable<DecodedToken> {
    try {
      if (!token) {
        token = this.getToken();
      }
      return this.decodeTokenFromAPI(token);
    } catch (e) {
      console.log(e);
      return of(undefined);
    }
  }
  decodeTokenFromAPI(token: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/decode_token/' + token);
  }
  login(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/login', data);
  }
  logout() {
    this.cookies.delete('token');
    this.transferState.remove(this.TOKEN);
    this.userservice.isLogged.next(false);
    this.userservice.user_info.next(null);
  }

  getNotifs(): Observable<any> {
    return this.http.get(
      environment.apiUrl +
        '/api/getNotifications/' +
        this.userservice.user_info.value.email
    );
  }
  async getAddress(): Promise<string> {
    const address: any = await this.http
      .get(`https://ipinfo.io/81.251.150.45\?token=8927bc903c78ca`)
      .toPromise();
    return `${address.region}, ${address.city}`;
  }

  verifyPassword(email: string, password: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/verifyPassword', {
      email: email,
      password: password,
    });
  }
  addStranger(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/strangerEmail', data);
  }

  register(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/register', data);
  }
  checkToken(token): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/reset-pass/' + token);
  }
  askForEmailUpdate(newEmail: string) {
    return this.http.post(`${environment.apiUrl}/api/sendConfirmationEmail`, {
      email: newEmail,
    }) as Observable<{ success: boolean; message: string }>;
  }
  send(data): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    let options = {
      headers: headers,
    };
    const dataStringifyed = JSON.stringify(data);
    return this.http.post(
      environment.apiUrl + '/api/resend',
      dataStringifyed,
      options
    );
  }

  reset(data, token): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/api/pass-change/' + token,
      data
    );
  }
  updatePassword(data: IParentInterfaces.resetPass): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/pass-update', data);
  }
  sendpass(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/reset-pass', data);
  }
}
