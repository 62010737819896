import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ssrCookie: SsrCookieService
  ) {}

  set(property: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      let date = new Date();
      date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + date.toUTCString();

      // Set the cookie
      document.cookie = `${property}=${value}; ${expires}; path=; domain=`;
    } else {
      return this.ssrCookie.set(property, value);
    }
  }
  get(property: string): string {
    if (isPlatformBrowser(this.platformId)) {
      const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
        const [name, value] = cookie.split('=').map((c) => c.trim());
        cookies[name] = value;
        return cookies;
      }, {});
      return cookies[property];
    } else {
      return this.ssrCookie.get(property);
    }
  }
  delete(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else {
      this.ssrCookie.delete(name);
    }
  }
}
